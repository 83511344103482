import { VariantProps, cva } from 'class-variance-authority';

export const tooltip = cva(
  [
    'shadow-2',
    'max-w-60',
    'break-words',
    'rounded-lg',
    'border',
    'border-solid',
    'px-4',
    'py-3',
    'text-center',
    'text-sm',
    'outline-none',
  ],
  {
    variants: {
      variant: {
        dark: ['bg-grey-900', 'border-grey-800', 'text-white'],
        light: ['bg-white', 'border-grey-300', 'text-grey-900'],
      },
    },
    defaultVariants: {
      variant: 'dark',
    },
  },
);

export type TooltipVariantsProps = VariantProps<typeof tooltip>;
