import { isFunction } from '@tiptap/react';
import { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { PlacementAxis } from 'react-aria';
import {
  OverlayArrow,
  Tooltip,
  TooltipTrigger,
  type TooltipProps,
  type TooltipTriggerComponentProps,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { Pressable } from '../../atoms/pressable/pressable';
import { tooltip } from '../../electrons/tooltip';
import { useIsMobile } from '../../helpers/useIsMobile';

interface ClickableTooltipTriggerProps extends Omit<TooltipTriggerComponentProps, 'children'> {
  children?: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  buttonComponent?: React.ReactNode;
  className?: string;
}

interface _TooltipProps extends Omit<TooltipProps, 'children'> {
  children: React.ReactNode;
}

function _Tooltip({
  children,
  className,
  variant,
  ...props
}: _TooltipProps & VariantProps<typeof tooltip>) {
  return (
    <Tooltip
      className={(bag) =>
        twMerge(
          clsx(
            tooltip({ variant }),
            bag.isEntering && 'animate-in fade-in duration-300 ease-out',
            bag.isExiting && 'animate-out fade-out duration-200 ease-in',
          ),
          isFunction(className) ? className(bag) : className,
        )
      }
      offset={5}
      placement="top"
      {...props}>
      <OverlayArrow>
        {({ placement }) => {
          const rotationClass = getPlacementStyle(placement as PlacementAxis);
          return (
            <svg
              className={rotationClass}
              fill="none"
              height="9"
              viewBox="0 0 28 9"
              width="28"
              xmlns="http://www.w3.org/2000/svg">
              <path
                className={clsx(variant === 'light' ? 'text-white' : 'text-grey-900')}
                d="M12.5858 2.41617L9.74904 5.25292C8.36542 6.63653 7.67361 7.32834 6.86628 7.82307C6.1505 8.2617 5.37015 8.58494 4.55386 8.78091C3.63316 9.00195 2.65479 9.00195 0.698068 9.00195H27.3019C25.3452 9.00195 24.3668 9.00195 23.4461 8.78091C22.6299 8.58494 21.8495 8.2617 21.1337 7.82307C20.3264 7.32834 19.6346 6.63653 18.251 5.25292L15.4142 2.41616C14.6332 1.63512 13.3668 1.63512 12.5858 2.41617Z"
                fill="currentColor"
              />
              <path
                className={clsx(variant === 'light' ? 'text-grey-300' : 'text-grey-700')}
                d="M27.3019 8.00195C25.2976 8.00195 24.4584 7.99553 23.6796 7.80854C22.9653 7.63707 22.2825 7.35424 21.6562 6.97043C20.9733 6.55193 20.3753 5.96308 18.9581 4.54581L16.1213 1.70906C14.9497 0.537485 13.0503 0.537489 11.8787 1.70906L9.04193 4.54581C7.62466 5.96308 7.02672 6.55193 6.34378 6.97043C5.71748 7.35424 5.03466 7.63707 4.32041 7.80854C3.54157 7.99553 2.70239 8.00195 0.698068 8.00195H0V9.00195H0.698068C2.65479 9.00195 3.63315 9.00195 4.55386 8.78091C5.37015 8.58494 6.1505 8.2617 6.86628 7.82307C7.67361 7.32834 8.36542 6.63653 9.74904 5.25292L12.5858 2.41617C13.3668 1.63512 14.6332 1.63512 15.4142 2.41616L18.251 5.25292C19.6346 6.63653 20.3264 7.32834 21.1337 7.82307C21.8495 8.2617 22.6299 8.58494 23.4461 8.78091C24.3668 9.00195 25.3452 9.00195 27.3019 9.00195H28V8.00195H27.3019Z"
                fill="currentColor"
              />
            </svg>
          );
        }}
      </OverlayArrow>
      {children}
    </Tooltip>
  );
}

export { _Tooltip as Tooltip };

const getPlacementStyle = (placement: PlacementAxis) => {
  switch (placement) {
    case 'top':
      return 'rotate-180';
    case 'right':
      return '-rotate-90 translate-x-[9px]';
    case 'left':
      return 'rotate-90 -translate-x-[9px]';
    case 'bottom':
    default:
      return 'rotate-0';
  }
};
export function ClickableTooltipTrigger({
  children,
  isOpen,
  setIsOpen,
  delay = 100,
  closeDelay = 100,
  buttonComponent,
  ...props
}: ClickableTooltipTriggerProps) {
  const tooltipButtonRef = useRef<HTMLButtonElement>(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isMobile) return;
    const handleClickOutside = (event: MouseEvent) => {
      const isClickOutsideTooltipButton = !tooltipButtonRef?.current?.contains(
        event.target as Node,
      );
      if (isClickOutsideTooltipButton) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile]);

  const toggleTooltipVisibility = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!isMobile) return;
    const closestForm = tooltipButtonRef?.current?.closest('form');

    if (closestForm) {
      closestForm.style.overflow = isOpen ? 'hidden' : 'auto';
    }
  }, [isOpen]);

  return (
    <TooltipTrigger closeDelay={closeDelay} delay={delay} {...(isMobile && { isOpen })}>
      <Pressable
        className="flex outline-none"
        onPress={toggleTooltipVisibility}
        ref={tooltipButtonRef}
        {...props}>
        {buttonComponent}
      </Pressable>
      {children}
    </TooltipTrigger>
  );
}
